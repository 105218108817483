import { MusicNote, ShoppingCart } from "@mui/icons-material";
import AspectRatio from "@mui/joy/AspectRatio";
import Box from "@mui/joy/Box";
import Button from "@mui/joy/Button";
import Card from "@mui/joy/Card";
import CardActions from "@mui/joy/CardActions";
import CardContent from "@mui/joy/CardContent";
import Container from "@mui/joy/Container";
import CssBaseline from "@mui/joy/CssBaseline";
import Divider from "@mui/joy/Divider";
import Grid from "@mui/joy/Grid";
import Typography from "@mui/joy/Typography";
import { CssVarsProvider } from "@mui/joy/styles";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import "react-lite-youtube-embed/dist/LiteYouTubeEmbed.css";

const MusicCard = ({
  title,
  description,
  available,
  link,
}: {
  title: string;
  description: string;
  available: boolean;
  link?: string;
}) => (
  <Card
    variant="outlined"
    sx={{ height: "100%", display: "flex", flexDirection: "column" }}
  >
    <CardContent>
      <Typography level="h2" fontSize="md" sx={{ mb: 0.5 }}>
        {title}
      </Typography>
      <Typography level="body-sm">{description}</Typography>
    </CardContent>
    <CardActions sx={{ mt: "auto", pt: 2 }}>
      <Button
        startDecorator={available ? <ShoppingCart /> : <MusicNote />}
        variant={available ? "solid" : "soft"}
        color={available ? "primary" : "neutral"}
        disabled={!available}
        fullWidth
        component={available ? "a" : "button"}
        href={link}
        target="_blank"
        rel="noopener noreferrer"
      >
        {available ? "Purchase" : "Coming Soon"}
      </Button>
    </CardActions>
  </Card>
);

const App = () => {
  return (
    <CssVarsProvider>
      <CssBaseline />
      <Box sx={{ minHeight: "100vh", bgcolor: "background.body" }}>
        {/* <ColorSchemeToggle /> */}
        <Container maxWidth="lg" sx={{ py: 4 }}>
          <Typography
            level="h1"
            fontSize="xl4"
            sx={{ mb: 4, textAlign: "center" }}
          >
            Simeon Loring Music
          </Typography>

          <Grid container spacing={4} sx={{ mb: 6 }}>
            <Grid
              xs={12}
              md={6}
              sx={{ display: "flex", flexDirection: "column" }}
            >
              <Typography level="body-lg" sx={{ mb: 2 }}>
                Welcome to my collection of string quartet masterworks arranged
                for woodwind quintet. As a wind player, I believe we deserve
                access to the marvelous chamber music composed since Haydn.
                Explore my arrangements of Beethoven, Mendelssohn, Schubert,
                Grieg, Mozart, and more.
              </Typography>
              <Box sx={{ mt: "auto" }}>
                <Typography level="body-md" sx={{ mb: 2 }}>
                  Listen to a sample from the Grieg Holberg Suite:
                </Typography>
                <Box
                  sx={{
                    "& .rhap_container": {
                      boxShadow: "sm",
                      borderRadius: "md",
                      bgcolor: "background.level1",
                    },
                    "& .rhap_main-controls-button, & .rhap_volume-button": {
                      color: "primary.mainChannel",
                    },
                    "& .rhap_progress-filled": {
                      bgcolor: "primary.mainChannel",
                    },
                    "& .rhap_download-progress": {
                      bgcolor: "primary.softBg",
                    },
                  }}
                >
                  <AudioPlayer
                    src={`${process.env.PUBLIC_URL}/grieg-holberg.mp3`}
                    layout="stacked"
                    autoPlay={false}
                    autoPlayAfterSrcChange={false}
                  />
                </Box>
              </Box>
            </Grid>
            <Grid xs={12} md={6}>
              <AspectRatio
                ratio="16/9"
                sx={{
                  borderRadius: "md",
                  overflow: "hidden",
                  boxShadow: "md",
                }}
              >
                <LiteYouTubeEmbed
                  id="g3Lpn9sJvTE"
                  title="Simeon Loring Music"
                  params="start=2"
                />
              </AspectRatio>
            </Grid>
          </Grid>

          <Divider sx={{ my: 4 }} />

          <Typography
            level="h2"
            fontSize="xl2"
            sx={{ mb: 3, textAlign: "center" }}
          >
            Featured Arrangements
          </Typography>
          <Grid container spacing={3}>
            <Grid xs={12} sm={6} md={4}>
              <MusicCard
                title="Grieg Holberg Suite"
                description="All movements of this beautiful suite reimagined for woodwinds."
                available={true}
                link="https://simeonloring.gumroad.com/l/holberg-suite"
              />
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <MusicCard
                title="Beethoven Opus 18 #1"
                description="A classic string quartet arranged for woodwind quintet."
                available={true}
                link="https://simeonloring.gumroad.com/l/beethoven-opus-18-1"
              />
            </Grid>
            <Grid xs={12} sm={6} md={4}>
              <MusicCard
                title="Schubert Death and the Maiden"
                description="Schubert's intense and dramatic masterwork, reimagined for woodwind quintet. "
                available={true}
                link="https://simeonloring.gumroad.com/l/death-and-the-maiden"
              />
            </Grid>
          </Grid>

          <Box sx={{ mt: 6, textAlign: "center" }}>
            <Typography level="body-lg" sx={{ mb: 2 }}>
              Hear more of my arrangements on:
            </Typography>
            <Button
              component="a"
              href="https://www.lamoredellamusica.org"
              target="_blank"
              rel="noopener noreferrer"
              variant="outlined"
              size="lg"
            >
              Visit L’Amore della Musica
            </Button>
          </Box>
        </Container>
      </Box>
    </CssVarsProvider>
  );
};

export default App;
